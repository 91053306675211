
#contact-bigger-container{
  scroll-margin-top: 90px;
}

#contactcontainer {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align items at the top */
    gap: 100px; /* Space between contact info and form */
    padding: 20px;
  }
  
  .contact-info {
    width: 100%;
    max-width: 400px;
    align-items: center;
    align-content: center;
    text-align: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 20px;
    padding-top:50px;
    padding-right:20px;
  }
  .contact-form {
    width: 50%;
    max-width: 400px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .contact-form label {
    margin-top: 10px;
    font-weight: bold;
  }
  
  .contact-form input, .contact-form textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .contact-form button {
    margin-top: 15px;
    padding: 10px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #555;
  }
  
  .feedback {
    margin-top: 10px;
    color: green;
    font-weight: bold;
  }

#contactcontainer p{
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 20px;
}

#contactcontainer2 a{
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 16px;
    font-weight: bolder;
    padding-top:50px;
    padding-left:10px;
    padding-right: 10px;
}

#contactcontainer2{
    bottom: 20px;
}


.contact-text{
    display: flex;
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: rgb(26, 141, 41);
}
@media (max-width: 768px) {

    
    .contact-form {
        width: 100%;
        max-width: 400px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        text-align: left;
      }


    .contact-info {
       padding-top: 10px;
      }
 
    #contactcontainer {
      flex-direction: column; /* Stack vertically on smaller screens */
      align-items: center;
      gap: 50px; /* Space between contact info and form */
    }
    

    #contactcontainer2 a{
        font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        font-size: 16px;
        font-weight: bolder;
        padding-top:20px;
        padding-left:10px;
        padding-right: 10px;
    }

}