#invisibleplaceholder {
    visibility: hidden !important;
} 
  
  #service-section{
    scroll-margin-top: 90px;
    }
    
    #service-section h2{
        color: rgb(26, 141, 41);
        font-size: 20px;
        padding-bottom: 20px;
    }
    
    #service-section p {
        font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    }
    .service-text{
        display: flex;
        padding-bottom: 40px;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        color: rgb(26, 141, 41);
    }
    
    service-leipäteksti {
        font-weight: 100; /* Set the font weight to a lighter value */
        color: #555; /* Optional: Change text color to a lighter shade */
    }
    
    @media (min-width: 768px) { /* This applies the style on screens larger than 768px (typically tablets and up) */
        #service-section .flex {
            margin-bottom: 30px; /* Adds spacing between the rows */
        }
    
        #service-section p {
            max-width: 350px; 
            text-align: center; 
            word-wrap: break-word; 
        }
    }
    
    @media (max-width: 767px) { /* This applies the style on screens smaller than 768px (mobile devices) */
        #service-section .flex {
            margin-bottom: 0; /* Removes the margin for mobile */
        }
    }