
.hero-container {
    background: url('/src/assets/images/tausta2.png') top/cover no-repeat;
    height: 85vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    opacity:0.8;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba (0, 0, 0, 0.2);
    object-fit: scale-down;
}

.smaller-container {
    height: 55vh;
    width: 800px;
    display: flex;
    padding: 10px 20px 20px 10px;
    flex-direction: column;
    margin-top: 10vh;
    opacity:0.9;
    background-color: rgb(44, 150, 44);
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 0 1000px rgba (0, 0, 0, 0.2);
    object-fit: scale-down;
}


.smaller-container>h1 {
    color: rgb(255, 255, 255);
    font-size: 50px;
    text-align: center;
    /* margin-top: 50px; */
    margin-bottom: 50px;
    /* padding-left:20px;
    padding-right:20px; */

}


@media screen and (max-width: 980px) {
    .hero-container {
        height: 70vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: inset 0 0 0 1000px rgba (0, 0, 0, 0.2);
        object-fit: scale-down;
    }

    .smaller-container {
        height:40vh;
        width: 75%;
        display: flex;
        padding: 20px 20px 0px 20px;
        flex-direction: column;
        margin-top: 10vh;
        opacity:0.9;
        background-color: rgb(44, 150, 44);
        align-items: center;
        box-shadow: inset 0 0 0 1000px rgba (0, 0, 0, 0.2);
        object-fit: scale-down;
    }

    .smaller-container>h1 {
        color: rgb(255, 255, 255);
        font-size: 40px;
        text-align: center;
        /* margin-top: 50px; */
        margin-bottom: 40px;
        /* margin-left: 5px;
        margin-right: 5px; */
    
    }

}


@media screen and (max-width: 640px) {
    .hero-container {
        height: 90vh;
        padding-top:20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: inset 0 0 0 1000px rgba (0, 0, 0, 0.2);
        object-fit: scale-down;
    }

    .smaller-container {
        height: 60vh;
        max-height: 70vh;
        align-items: center;
        align-content: center;
        width: 80vw;
        
    }
    .smaller-container>h1 {
        color: rgb(255, 255, 255);
        font-size:35px;
        text-align: center;
        /* margin-top: 50px; */
        margin-bottom: 40px;
        /* margin-left: 5px;
        margin-right: 5px; */
    
    }
    
}