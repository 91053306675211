
#aboutcontainer{
    background-color: rgb(26, 141, 41, .2);
    scroll-margin-top: 90px;
}

#aboutcontainer h2{
    font-weight: light !important;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
#aboutcontainer p{
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.about-text{
    display: flex;
    padding-bottom: 40px;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: rgb(26, 141, 41);
}


.custom-hr{
    border: none; 
    height: 2px; 
    background-color: rgb(26, 141, 41);
    margin-top: 40px; 
    margin-bottom: 40px;
  }

  .mobile-break {
    display: none; /* Hidden by default */
}

/* Media query for mobile devices (up to 768px wide) */
@media (max-width: 768px) {
    .mobile-break {
        display: block; /* Show on mobile */
        height: 1px; /* Thickness of the line */
        background-color: rgb(26, 141, 41); 
        margin: 20px 0; /* Spacing above and below */
    }
}